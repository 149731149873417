.btn-custom-login {
  background: #5865f2;
  font-size: 12px;
}

.btn-custom-login:hover {
  background: #3541c4;
}

.btn-not-allowed {
  background: #ff8503;
  color: white;
  font-size: 20px;
}

.btn-not-allowed:hover {
  background: #ff8503d4;
}

.login-modal {
  background: #5865f2;
}

.login-modal:hover {
  background: #3541c4;
}

.ReactModal__Content {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

@font-face {
  font-family: "Scurlock";
  src: local("Trickster"),
    url("../public/assets/fonts/scurlock.TTF") format("truetype");
}

.font-scurlock {
  font-family: "Scurlock" !important;
}
